import { Link } from "react-router-dom";
import "./CustomCategories.css";
import { useContext } from "react";
import { ConfigContext } from "../../../../assets/contexts/configContext";
import { CATALOG_MAIN_LINK, IMG_PLACEHOLDER_PRODUCT } from "../../../../assets/utils/constants";
import { buildCategoryPath } from "../../../../assets/utils/utils";

const usedTranslitNames = [
    "6696737eac14d0f80b4b4ab9",
    "669671b6ac14d0f80b4b470b",
    "669671d9ac14d0f80b4b4763",
    "66967215ac14d0f80b4b47d2",
    "669671dbac14d0f80b4b476f",
    "669673d0ac14d0f80b4b4ba7",
    "66967528ac14d0f80b4b4ea9",
    "669671b6ac14d0f80b4b470d",
    "669673caac14d0f80b4b4b8a",
    "669671aaac14d0f80b4b46f0",
    "66967313ac14d0f80b4b4a2c",
    "669675d8ac14d0f80b4b50c8",
    "669671b5ac14d0f80b4b4704",
    "669671c7ac14d0f80b4b474d",
    "66967279ac14d0f80b4b4896",
    "66967457ac14d0f80b4b4ccc",
    "669672b9ac14d0f80b4b48f4",
    "66f3f4c9bce452059b6ddd38",
    "66967564ac14d0f80b4b4f62",
];


function CustomCategories() {
    const config = useContext(ConfigContext);

    const { initialConstants, cardTokens } = config
    const { FIRST_LEVEL_CATEGORIES } = initialConstants
    const { image_radius } = cardTokens;

    const filteredItems = [...FIRST_LEVEL_CATEGORIES.filter(item => usedTranslitNames.includes(item._id))];

    const sortItems = (items, order) => {
        const orderMap = new Map(order.map((name, index) => [name, index]));
        return items.filter(item => orderMap.has(item._id))
            .sort((a, b) => orderMap.get(a._id) - orderMap.get(b._id));
    };

    const sortedItems = sortItems(filteredItems, usedTranslitNames);

    return (
        <div className={`custom-categories`} >
            {sortedItems && sortedItems.length > 0 ?
                sortedItems.map((item, i) => (
                    <Link className="custom-categories__item"
                        to={`/${CATALOG_MAIN_LINK}/${item.translit_name}`}
                        key={item.translit_name}
                    >
                        {console.log(item)}
                        <img
                            className="custom-categories__img"
                            src={item.file ? item.file.urls.orig : IMG_PLACEHOLDER_PRODUCT}
                            alt={item.name}
                            style={{
                                borderRadius: image_radius,
                            }}
                        />
                        {/* {item.name} */}
                    </Link>
                ))
                :
                null}
        </div >
    );
}

export default CustomCategories;