import { Link, useSearchParams } from "react-router-dom";
import "./Goods.css";
import { CART_MAIN_LINK, CATALOG_ITEM_LINK, CATALOG_MAIN_LINK, IMG_PLACEHOLDER_PRODUCT, MAIN_URL } from "../../../assets/utils/constants";
import { useContext, useEffect, useState } from "react";
import mainApi from "../../../assets/api/MainApi";
import { ConfigContext } from "../../../assets/contexts/configContext";
import { buildCategoryPath, getCorrectWordForm, parsePrice } from "../../../assets/utils/utils";

function Goods({ cart, handleAddToCart, handleRemoveFromCart, products, setProducts }) {
  const [isPreloaderVisible, setPreloaderVisible] = useState(true);


  const config = useContext(ConfigContext)
  const { initialConstants } = config
  const { SHOP_ID_FOR_API, } = initialConstants
  const cartLocalStorageKey = `cart_${SHOP_ID_FOR_API}`;

  useEffect(() => {
    if (!cart || cart.length === 0) {
      setProducts([]);
      setPreloaderVisible(false);
      return;
    }
  
    const chunkSize = 25;
    let allProducts = [];
  
    // Функция для запроса чанков по 25 элементов
    const fetchChunk = (chunkedCart) => {
      if (chunkedCart.length === 0) {
        setPreloaderVisible(false);
        setProducts(allProducts);
        localStorage.setItem(cartLocalStorageKey, JSON.stringify(cart));
        return;
      }
  
      const currentChunk = chunkedCart.slice(0, chunkSize); // берем первые 25 элементов
  
      mainApi
        .getManyItems({
          data: {
            shop_id: SHOP_ID_FOR_API,
            ids: currentChunk.map((item) => item._id),
          },
        })
        .then((res) => {
          const filtered_cart = currentChunk.filter((item) => {
            let product = res.data.find(
              (product_item) => product_item._id === item._id
            );
            if (!product) {
              removeFromCart({
                ...product,
                ...item,
              }, true);
              return false;
            }
  
            const validOptions = item.options.every((option) => {
              const productOption = product.options.find(
                (product_option) => option.name === product_option.name
              );
              if (!productOption) {
                return false;
              }
  
              const optionValue = productOption.values.find(
                (product_option_value) => option.value === product_option_value.value
              );
              if (!optionValue) {
                return false;
              }
  
              return true;
            });
  
            if (!validOptions) {
              removeFromCart({
                ...product,
                ...item,
              }, true);
              return false;
            }
  
            return true;
          });
  
          const updatedProducts = filtered_cart.map((item) => {
            let product = res.data.find(
              (product_item) => product_item._id === item._id
            );
  
            const options = item.options.map((option) => {
              const productOption = product.options.find(
                (product_option) => option.name === product_option.name
              );
              const optionValue = productOption.values.find(
                (product_option_value) => option.value === product_option_value.value
              );
  
              return {
                name: productOption.name,
                value: optionValue.value,
                in_stock: optionValue.in_stock,
                price: optionValue.price,
              };
            });
  
            return {
              ...product,
              ...item,
              options: options,
            };
          });
  
          allProducts = [...allProducts, ...updatedProducts];
  
          // Продолжаем запрашивать следующие 25 элементов
          fetchChunk(chunkedCart.slice(chunkSize));
        })
        .catch((err) => {
          console.log(err);
          setPreloaderVisible(false);
        });
    };
  
    setPreloaderVisible(true);
    fetchChunk(cart);
  }, [cart]);

  function addToCart(item) {
    const product_to_card = {
      ...item,
      //   selected_size: item.selected_size,
      count: 1,
    };
    console.log(product_to_card);
    handleAddToCart(product_to_card);
  }

  function removeFromCart(item, is_all) {
    const product_to_card = {
      ...item,
      //   selected_size: item.selected_size,
    };
    console.log(product_to_card);
    handleRemoveFromCart(product_to_card, is_all);
  }

  return (
    <>
      {!isPreloaderVisible || products ? (
        <div className="goods">
          {products && products.length > 0 ? (
            products.map((item, i) => {
              console.log(item)
              const in_stock = item ? item.options.length === 1 ? item.options[0].in_stock : item.price_data.in_stock : null
              const price = item ? item.options.length === 1 ?  item.options[0].price : item.price_data.price : null
              return (
                <div
                  className="goods__card"
                  key={`goods__card_${item._id}${item.options ? item.options.map(option => option.name).join(', ') : "no-size"
                    }`}
                >
                  <Link
                    className="goods__card-img-box"
                    to={`/${CATALOG_MAIN_LINK}/${buildCategoryPath(item.categories[0].parents.reverse(), item.categories[0].parents.length - 1)}/${CATALOG_ITEM_LINK}/${item._id}` + `${item.options && item.options.length > 0 ? `?${new URLSearchParams({ options: JSON.stringify(item.options) })}` : ''}`}
                  >
                    <img
                      className={`goods__card-img`}
                      src={item.files && item.files.length > 0 ? item.files[0].urls.orig : IMG_PLACEHOLDER_PRODUCT}
                      alt=""
                    ></img>
                    {item.price_data.discount && item.price_data.discount > 0 ?
                      <h3 className='goods__price-discount'>-{item.price_data.discount}%</h3>
                      :
                      null
                    }
                  </Link>
                  <div className="goods__card-content">
                    <div className="goods__card-heading">
                      <div className="goods__card-title-and-controls">
                        <h3 className="goods__card-title">{item.name}</h3>

                        {in_stock ?
                          <div>

                            <div className="goods__controls">
                              <button
                                className="goods__control goods__control_type_left"
                                type="button"
                                onClick={() => {
                                  removeFromCart(item);
                                }}
                              >
                                −
                              </button>
                              <div className="goods__control-counter">
                                <p className={`goods__control-counter-text ${item.count > in_stock ? 'goods__control-counter-text_invalid' : ''}`}>
                                  {item.count}
                                </p>
                              </div>
                              <button
                                className={`goods__control goods__control_type_right ${Number(item.count) + 1 > Number(in_stock) ? 'goods__control_inactive' : ''}`}
                                type="button"
                                onClick={() => {
                                  addToCart(item);
                                }}
                              >
                                +
                              </button>
                            </div>
                            {item.count > in_stock ?
                              <h3 className="goods__not-in-stock-hint">В наличии: {in_stock} {getCorrectWordForm(in_stock)}</h3>
                              :
                              null
                            }
                          </div>
                          :
                          <div className="goods__not-in-stock">
                            <h3 className="goods__not-in-stock-title">Нет в наличии</h3>
                            <button
                              className="goods__not-in-stock-btn"
                              type="button"
                              onClick={() => {
                                removeFromCart(item, true)
                              }}>
                              <h3 className="goods__not-in-stock-btn-text">Убрать из корзины</h3>
                            </button>
                          </div>
                        }
                      </div>
                      <div className="goods__price">
                        {item.price_data.discount && item.price_data.discount > 0 ?
                          <h3 className='goods__price-value '><span className='goods__price-value-discounted'>{Number(price)}</span> {parsePrice(Number(price) - Number(price) / 100 * Number(item.price_data.discount), 'RUB')}</h3>
                          :
                          <h3 className='goods__price-value'>{parsePrice(price, 'RUB')}</h3>
                        }
                        {/* <h3 className="goods__price-value">
                        {parsePrice(item.price_data?.discount ? (
                          Number(item.price_data?.price) -
                          (Number(item.price_data?.price) / 100) *
                          Number(item.price_data?.discount)
                        )
                          : item.price_data?.price, 'RUB')}
                      </h3>
                      {item.price_data?.discount ? (
                        <>
                          <p className="goods__price-discount">
                            -
                            {Number(item.price_data?.discount).toLocaleString()}
                            %
                          </p>
                          <p className="goods__price-previous">
                            {Number(item.price_data?.price)}
                          </p>
                        </>
                      ) : null} */}
                      </div>
                    </div>
                    {item.options && item.options.length > 0 ?
                      <div className="goods__params">
                        {item.options.map((option) => (
                          <div className="goods__param">
                            <p className="goods__param-name">{option.name}</p>
                            <div className="goods__param-line"></div>
                            <p className="goods__param-value">{option.value}</p>
                          </div>
                        ))}

                      </div>
                      : null}

                    {/* <div className="goods__card-discriptions">


                            <div className="goods__card-discription">

                                <div className="goods__discription-detail">
                                    <span className='goods__discription-detail-name'>Размер</span>
                                    <div className="goods__discription-detail-line"></div>
                                    <span className='goods__discription-detail-meaning'>W28 L32</span>
                                </div>

                                <div className="goods__discription-detail">
                                    <span className='goods__discription-detail-name'>Размер</span>
                                    <div className="goods__discription-detail-line"></div>
                                    <span className='goods__discription-detail-meaning'>W28 L32</span>
                                </div>

                                <div className="goods__discription-detail">
                                    <span className='goods__discription-detail-name'>Размер</span>
                                    <div className="goods__discription-detail-line"></div>
                                    <span className='goods__discription-detail-meaning'>W28 L32</span>
                                </div>

                                <div className="goods__discription-detail">
                                    <span className='goods__discription-detail-name'>Размер</span>
                                    <div className="goods__discription-detail-line"></div>
                                    <span className='goods__discription-detail-meaning'>W28 L32</span>
                                </div>

                            </div>

                        </div> */}
                  </div>
                </div>
              )
            })
          ) : (
            <div className="no-goods">
              <svg
                className="no-goods__empty-icon"
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="no-goods__empty-icon-fill"
                  d="M33.5201 76.1583C36.2594 76.1583 38.4801 73.9376 38.4801 71.1983C38.4801 68.4589 36.2594 66.2383 33.5201 66.2383C30.7807 66.2383 28.5601 68.4589 28.5601 71.1983C28.5601 73.9376 30.7807 76.1583 33.5201 76.1583Z"
                  fill="#E11C23"
                />
                <path
                  className="no-goods__empty-icon-fill"
                  d="M56.4001 76.1583C59.1394 76.1583 61.3601 73.9376 61.3601 71.1983C61.3601 68.4589 59.1394 66.2383 56.4001 66.2383C53.6607 66.2383 51.4401 68.4589 51.4401 71.1983C51.4401 73.9376 53.6607 76.1583 56.4001 76.1583Z"
                  fill="#E11C23"
                />
                <path
                  className="no-goods__empty-icon-fill"
                  d="M68.08 30.5605H66.24C65.52 30.5605 64.72 30.5605 64 30.5605H28.56L28 28.8005C26.88 25.0405 23.44 22.4805 19.52 22.4805H8.08003C5.68003 22.4805 3.84003 24.5605 4.08003 26.9605C4.32003 29.0405 6.16003 30.5605 8.32003 30.5605H14.8C16.8 30.5605 18.64 31.9205 19.2 33.8405L20.56 38.4005C20.56 38.4805 20.56 38.5605 20.56 38.5605L26.32 57.6005C27.28 60.7205 30.16 62.8805 33.44 62.8805H58.24C61.6 62.8805 64.56 60.6405 65.36 57.3605L69.36 38.4005C70.8 37.9205 72 36.6405 72.16 35.0405C72.32 32.6405 70.4 30.5605 68.08 30.5605ZM46 56.6405C40.56 56.6405 36.08 52.2405 36.08 46.7205C36.08 41.2805 40.48 36.8005 46 36.8005C51.44 36.8005 55.92 41.2005 55.92 46.7205C55.92 52.2405 51.52 56.6405 46 56.6405Z"
                  fill="#E11C23"
                />
                <path
                  className="no-goods__empty-icon-fill"
                  d="M48.32 46.7205L50.88 44.1605C51.36 43.6805 51.36 42.8805 50.88 42.4005L50.4 41.8405C49.92 41.3605 49.12 41.3605 48.64 41.8405L46.16 44.3205L43.68 41.8405C43.2 41.3605 42.4 41.3605 41.92 41.8405L41.2 42.4005C40.72 42.8805 40.72 43.6805 41.2 44.1605L43.68 46.6405L41.12 49.2005C40.64 49.6805 40.64 50.4805 41.12 50.9605L41.6 51.4405C42.08 51.9205 42.88 51.9205 43.36 51.4405L45.92 48.8805L48.48 51.4405C48.96 51.9205 49.76 51.9205 50.24 51.4405L50.72 50.9605C51.2 50.4805 51.2 49.6805 50.72 49.2005L48.32 46.7205Z"
                  fill="#E11C23"
                />
              </svg>
              <h3 className="no-goods__title">Ваша корзина пуста</h3>
              <Link className="no-goods__go-main" to="/">
                <p className="no-goods__go-main-text">‹ Вернуться на главную</p>
              </Link>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
}

export default Goods;
